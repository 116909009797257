.radio_box {
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    margin: 5px;
    padding-right: 15px;
    color: #333;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.check_box_sty {
    padding: 0px 5px;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    margin: 5px;
    color: #333;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}