@import 'flexmonster/flexmonster.css';
/* 
 */

 div::-webkit-scrollbar {
    width: 10px;
}

div::-webkit-scrollbar-track {
    background-color: #f5f5f5;
    border-radius: 4px;
}

div::-webkit-scrollbar-thumb {
    background: linear-gradient(to right, #0c247bc4, #337ab7); /* Use your preferred color or gradient */
    border-radius: 4px;
    border: 2px solid #ffffff; /* Add a border for a more polished look */
}
