.card-bg {
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    display: flex;
    height: 80px;
    padding: 16px;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
}

.title {
    color: #6F6F6F;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.count {
    color: #393939;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.icon {
    border-radius: 100px;
    background: #F1F2F4;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

@media only screen and (max-width: 1380px) {
    .icon {
        width: 32px;
        height: 32px;
    }

    .card-bg {
        padding: 8px;
    }
}

@media only screen and (max-width: 1200px) {
    .icon {

        width: 40px;
        height: 40px;

    }

    .card-bg {
        padding: 16px;
    }
}