  .ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell{
    background-color: #fff;
}

  .ag-theme-alpine .ag-row-even{
    background-color: #f5f9fc;
  }
  .ag-theme-alpine .ag-row-odd {
    background-color: #fff;
  }

 .ag-theme-alpine .ag-row {
    color: #3d3d3d;
    font-size: 14px;
}
  


  .my-class{
      text-align: center;
  }
  
  
  .lock-pinned {
      background-color: #f7f7f7ea;
    }

.card-background {
    border-radius: 6px;
    background: var(--white, #FFF);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    height: 100%;
    width: 100%;
}

.title-text {
    color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.sub-text {
    color: var(--value-color, #0B1354);
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.9px;
}

.search-input {
    border: 1px solid #DDE1E6;
    background: #FFF;
    color: #000;
    overflow: hidden;
    padding: 8px;
    border-radius: 20px;
}

.search-input:focus {
    outline: none;
    border: 1px solid #0e356c;
}

.munu_list {
    margin: 0px;
    border-bottom: 1px solid #E6E6E6;
    padding: 5px 20px;
}

/*************************/
.ag-header-cell-label {
    display: flex;
    justify-content: center;
  }
  
  div.ag-root .ag-cell-focus {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  .ag-header-cell-text {
    font-size: 13px; /* Set your desired font size here */
}

div.ag-theme-alpine div.ag-row {
  font-size: 12px !important;
}


.ag-header-group-cell-label, .ag-header-cell-label {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  align-items: center;
  text-overflow: ellipsis;
  align-self: stretch;
  justify-content: center;
}