.score-cell {
    font-style: italic;
}

.green-row div {
    background-color: #f2f5f7;
}

.leaflet-container {
    width: 100%;
    height: 100vh;
}

.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
    filter: invert(90%) hue-rotate(300deg) brightness(90%) contrast(250%);
}

.css-fznvei {
    margin-top: 45px !important;
}

.logo {
    width: 25.255px;
    height: 24px;
}

.logo-text {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 166.667% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding-left: 1rem;
    text-decoration: 'none';
}

menu-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}

menu-text {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.MuiInputBase-root {
    border-radius: 8px !important;
    border: 1px solid #d6dee6;
}

.MuiOutlinedInput-notchedOutline {
    border: none;
}

.MuiAutocomplete-hasPopupIcon.css-eyhdg2-MuiAutocomplete-root .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-eyhdg2-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: 0%;
}

.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root:hover {
    border: 1px solid #0e6c5b;
    padding: 2px;
}

.css-1uon4df-MuiInputBase-root-MuiOutlinedInput-root {
    background: #fff;
}

.css-sixosc-MuiInputBase-input-MuiOutlinedInput-input {
    background: #fff;
}

.css-jaushf-MuiInputBase-input-MuiOutlinedInput-input {
    background: #fff;
}

.css-wa48gc-MuiInputBase-input-MuiOutlinedInput-input {
    border-radius: 10px;
    min-height: 8px;
}

.custom-textfield .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 9px 13px !important;
}

.filter-title {
    color: #000;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.clear-filter {
    color: #6f6f6f;
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
}

.filter-header {
    display: flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    border-bottom: 1px solid #dde1e6;
}

.count-style {
    border-radius: 50%;
    background: #f8d5ad;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    height: 20px;
}

.drawar-style {
    margin: 2rem;
    border-radius: 8px;
}

/* Box layout (Parent container of the buttons) */
.box-layout {
    display: flex;
    height: 48px;
    padding: 0px 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    align-self: stretch;
    border-top: 1px solid #dde1e6;
}

/* Apply button */
.apply-button {
    display: flex;
    height: 36px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 5px;
    background: #ee9329;
}

.apply-button:hover {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    background: #ee9329;
}

/* Cancel button */
.cancel-button {
    display: flex;
    height: 36px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    background: #fff;
    color: #7a7880;
}

.cancel-button:hover {
    border: 1px solid #0e6c5b;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
}

/* .css-1kxbtff-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    padding-top: 2px;
    padding-bottom: 3px;
    padding-left: 0;
} */

/* .MuiAutocomplete-hasPopupIcon.css-1kxbtff-MuiAutocomplete-root .MuiOutlinedInput-root, */
/* .MuiAutocomplete-hasClearIcon.css-1kxbtff-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: 0;
} */

/* .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
    display: none;
} */

.css-1kxbtff-MuiAutocomplete-root .MuiAutocomplete-tag {
    margin-left: 4px;
    min-width: 35px;
    padding: 0px;
    max-width: calc(100% - 4px);
}

.child-component {
    transition: all 0.3s ease-in-out;
}

.child-component.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 9999;
}

.error-message {
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

/* .field_main {
  margin: 8px 8px 0px 8px;
  height: 45px;
}

.field_main input {
  height: 10px;
}

.MuiInputBase-root .field_main {
  height: 20px !important;
} */


