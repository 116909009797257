
.filter-box{
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
    height: 32px;
    margin:auto 3px;
    padding: 5px;
    margin-bottom: 5px;
    }

.flexee{
    display: flex;
    align-items: center;
    margin-right: 2px;
    margin-left: 2px;
}
.title{
    padding-left: 5px;
}

.children{
    padding-left: 5px;
    padding-right: 5px;
    color:#364152;
}